import { Head, useForm } from '@inertiajs/react';
import Typography from '../../components/Typography';
import LoginForm from '../../components/forms/LoginForm';
import AuthenticationLayout from '@/components/layouts/AuthenticationLayout';
import Link from '../../components/Link';
import Button from '../../components/Button';
import LoadingOverlay from '../../components/overlays/LoadingOverlay.jsx';
import Box from '@/components/Box';
import Panel from '@/components/Panel';
import asset from '@/utils/VaporUtil.js';
import { ArrowRightIcon } from '@heroicons/react/24/outline';

const Login = () => {
    const form = useForm({
        remember: true,
    });

    const handleSubmit = (event) => {
        event.preventDefault();
        form.submit('post', route('login'));
    };

    return (
        <>
            <Head title={'Inloggen'} />

            <Box display={'grid'} gridTemplateColumns={{ xs: '1fr', lg: '1fr 1fr' }}>
                <Box display={'flex'} alignItems={'center'} justifyContent={'center'} minHeight={'calc(100vh - 80px)'}>
                    <Panel className={'relative h-fit w-full max-w-lg'}>
                        <div>
                            <img className={'h-10'} src={asset('assets/images/logo/black.svg')} alt={'Capilex'} />
                        </div>

                        <LoadingOverlay loading={form.processing} />

                        <Typography fontSize={'lg'} mt={40}>
                            Inloggen
                        </Typography>

                        <LoginForm form={form} onSubmit={handleSubmit} mt={24} />

                        <Button className={'mt-5'} onClick={handleSubmit}>
                            <span className={'font-bold'}>Inloggen</span>
                            <ArrowRightIcon width={32} />
                        </Button>

                        <a href={route('investor_application.index')}>
                            <Button className={'mt-5 h-fit whitespace-break-spaces bg-blue-900 py-4 text-left lg:h-14 lg:whitespace-nowrap lg:py-0'}>
                                <span className={'font-bold'}>Vrijblijvend registreren als investeerder</span>
                                <ArrowRightIcon width={32} />
                            </Button>
                        </a>

                        <Typography className={'mt-5'}>
                            <Link href={route('password.request')}>Wachtwoord vergeten?</Link>
                        </Typography>
                    </Panel>
                </Box>

                <Box display={{ xs: 'none', lg: 'flex' }} alignItems={'center'} justifyContent={'center'} p={80} overflow={'hidden'} backgroundColor={'primary.base'} borderRadius={24}>
                    <Box as={'img'} src={asset('assets/images/house.png')} width={'100%'} />
                </Box>
            </Box>
        </>
    );
};

Login.layout = (children) => <AuthenticationLayout children={children} />;

export default Login;
